@use "src/utils";

.artist-search {
  height: 100%;
  width: 100%;
  @include utils.flexTemplate(flex-start, column, 3em);
  padding: 1em 16px 1em 16px;

  @media (min-width: utils.$tablet-screen) {
    padding: 1em 4em 1em 4em;
  }

  &__form {
    grid-column: 1 / -1;
    align-self: center;
    width: 100%;

    &__search-container {
      width: 100%;
      margin: 0 auto;

      @media (min-width: utils.$tablet-screen) {
        width: 50%;
      }
    }
  }

  &__cards-container {
    @include utils.gridTemplate(repeat(auto-fit, minmax(2em, 1fr)));
    gap: 4em;
    padding-top: 2em;
  }

  &__card {
    grid-column: span 3;
  }
}

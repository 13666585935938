@mixin gridTemplate($templateColumns: 1fr, $placeItems: center) {
  display: grid;
  grid-template-columns: $templateColumns;
  place-items: $placeItems;
}

@mixin flexTemplate($justifyContent: center, $direction: row, $gap: 0) {
  display: flex;
  flex-direction: $direction;
  justify-content: $justifyContent;
  gap: $gap;
}

@use "./utils";
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

:root {
  font-size: calc(16px + 0.1vw);
}

.wrapper {
  min-height: 100vh;
  height: 100%;
  width: 100%;
  @include utils.gridTemplate(1fr, center);
  grid-template-rows: 3.5em auto;
}

.centered__container {
  height: 100%;
  width: 100%;
  @include utils.gridTemplate();
}

// normalize

p,
h3 {
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
}

a {
  color: black;
}

@use "src/utils";

.card {
  &__container {
    @include utils.flexTemplate(space-between, column);
    max-width: 25em;
    width: 100%;
    align-items: flex-start;
    box-shadow: 0px 0px 8px black;
    border-radius: 4px;
    overflow: hidden;
    transition: 0.2s;
    text-decoration: none;

    &:hover {
      transform: scale(1.02);
      cursor: pointer;
    }
  }
  &__img-container {
    width: 100%;
    height: 12em;
    overflow: hidden;
    box-shadow: 0px -5px 25px black;
  }

  &__content-container {
    @include utils.flexTemplate(space-between, column);
    padding: 1em;
    height: 11em;
    width: 100%;

    &__holder {
      @include utils.flexTemplate(space-between, column, 0.5em);
    }
  }

  &__artist-name {
    font-size: 2rem;
    line-height: 1em;
    width: 100%;
    overflow-wrap: break-word;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &__artists-album-list {
    overflow-wrap: break-word;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

@use "src/utils";
.homepage {
  &__container {
    @include utils.flexTemplate(space-between, column, 0.5em);
    width: 100%;
    @media (min-width: utils.$tablet-screen) {
      width: 35%;
    }
    &__img {
      position: absolute;
      top: 50%;
      right: 0;
      transform: translate(0, -50%);
    }
  }
}
